<template>
  <div class="atom-divider" />
</template>

<script setup>

</script>

<style lang="scss" scoped>
.atom-divider {
    display: flex;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: $C_GREY_LINE;
}
</style>
